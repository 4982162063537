:root {
	--soft-red: hsl(7, 99%, 70%);
	--yellow: hsl(51, 100%, 49%);
	--dark-cyan: hsl(167, 40%, 24%);
	--medium-cyan: hsl(168, 34%, 41%);
	--dark-blue: hsl(198, 62%, 26%);
	--desaturated-blue: hsl(212, 27%, 19%);
	--very-dark-gray-blue: hsl(213, 9%, 39%);
	--dark-gray-blue: hsl(232, 10%, 55%);
	--gray-blue: hsl(210, 4%, 67%);
	--white: hsl(0, 0%, 100%);
	--serif: 'Fraunces', serif;
	--sans-serif: 'Barlow', sans-serif;
}

* {
	font-weight: 600;
    font-family: var(--sans-serif);
    transition: all 0.25s ease-in-out;
	box-sizing: border-box;
}

html {
	font-size: 18px;
}

body {
  	margin: 0;
}